<template>
   <v-container  fluid class="mb-12">
        <v-row class="mb-2">
            <v-col class="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Customer </h6>
                                <v-autocomplete 
                                dense 
                                solo 
                                class="ma-0 pa-1 border-12" 
                                hide-details=true 
                                v-model="customer"
                                :items="customers" 
                                default="" 
                                item-value="cust_id" 
                                item-text="cust_name" 
                                label="Customer"
                                clearable>
                                   
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">PPP/LPP </h6>
                                <v-autocomplete 
                                dense 
                                solo 
                                class="ma-0 pa-1 border-12" 
                                hide-details=true 
                                v-model="ppp"
                                :items="ppps" 
                                default="" 
                                label="PPP / LPM"
                                clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Start Period <strong style="color:red;">*</strong><span></span></h6>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To Period </h6>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="month" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="2" class="mt-8">
                              <div>
                                  <v-btn class="border-12" style="padding: 19px;" small color="info" elevation="2" @click="getPullData()">Search</v-btn>
                                  <v-spacer></v-spacer>
                              </div>
                          </v-col>
                    </v-row>
                </v-container>
                </v-card>
            </v-col>
        </v-row>
        <!-- v-if="tableVisible" -->
        <v-row>
        <v-col class="col-12">
            <v-card outlined>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="header"
                                :items="formattedData"
                                :loading="$store.state.overlay"
                                :item-class="tr_datatable"
                                :items-per-page="20"
                                fixed-header
                                height="500"
                                :divider="true"
                                :light="true"
                                :search="searchItem"
                                >        
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-100">
                                        <h6 class="blue-lcd text-darken-3 m-0">Result</h6>
                                        <v-spacer></v-spacer>
                                        <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                    <v-col cols="12" xs="12" sm="2" md="1">
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            small
                                            color="#005c37"
                                            class="py-5 mr-0 text-white"
                                            >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel
                                                class="text"
                                                :fetch           = "exportExcel"
                                                :fields="headersColumn"
                                                :before-generate = "startDownload"
                                                :before-finish   = "finishDownload">
                                                Export Excel
                                        </download-excel>
                                    </v-btn>
                                </v-col>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showDialogDetail(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>View Detail</span>
                                        </v-tooltip>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
        <v-dialog v-model="dialog" max-width="1000px">                
            <v-card>
                <!-- <v-card-title><span class="blue--text">PPP/LPM no: {{ pppLpmNo }}</span></v-card-title> -->
                <v-card-title>
                        <v-col cols="6">
                        Trans Date : <span class="cyan-text text-darken-3 m-0"> {{ dtTrx }}</span>
                        </v-col>
                        <v-col cols="6">
                            Customer : <span class="cyan-text text-darken-3 m-0"> {{ customerName }}</span>
                        </v-col>    
                        <v-col cols="6">
                            Lpm/PPP No : <span class="cyan-text text-darken-3 m-0"> {{ pppLpmNo }}</span>
                        </v-col>
                        <v-col cols="6">
                            Order No : <span class="cyan-text text-darken-3 m-0"> {{ orderNo }}</span>
                        </v-col>    
                </v-card-title>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headerDetails"
                                :items="formattedDetailData"
                                :loading="$store.state.overlay"
                                :item-class="tr_datatable"
                                :items-per-page="10"
                                fixed-header
                                height="500"
                                :divider="true"
                                :light="true"
                                >        
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialogDetail">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </v-row>
   </v-container>
</template>
<script>
export default {
    data(){
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'SM',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Shipment Analysis',
                    disabled: true,
                    href: '/admin/sm',
                },
                {
                    text: 'List of ppp',
                    disabled: true
                }
            ],
            customer:'',
            customers:[],
            ppp:'',
            ppps:['All','PPP', 'LPM'],
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            tableVisible:false,
            header:[
                {
                    text: 'Trans Date',
                    align: 'start',
                    value: 'dt_trx', 
                    align:'left'},
                { text: 'PPP/LPM', value: 'aprv_flag' , align:'left'},
                { text: 'LPM No', value: 'lpm_id' , align:'left'},
                { text: 'Order No', value: 'order_id' , align:'left'},
                { text: 'Cust Name', value: 'cust_name' , align:'left'},
                { text: 'Weight', value: 'wgt' , align:'right'},
                { text: 'Total Price', value: 'total_price' , align:'right'},
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            searchItem: '',
            data:[],
            detailData:[],
            dialog:false,
            dtTrx:'',
            customerName:'',
            pppLpmNo:'',
            orderNo:'',
            headerDetails:[
                {
                    text: 'Trans Date',
                    align: 'start',
                    value: 'dt_trx', 
                    align:'left'},
                { text: 'PPP/LPM', value: 'aprv_flag' , align:'left'},
                
                { text: 'Order No', value: 'order_id' , align:'left'},
                { text: 'Cust Name', value: 'cust_name' , align:'left'},
                { text: 'Item No', value: 'item_num' , align:'left'},
                { text: 'Prod Code', value: 'prod_code' , align:'left'},
                { text: 'Description', value: 'descr' , align:'left'},
                { text: 'Weight', value: 'wgt' , align:'right'},
                { text: 'Unit Price', value: 'unit_price' , align:'right'},
                { text: 'Total Price', value: 'total_price' , align:'right'},
            ],
        headersColumn:{
                'Trans Date': 'dt_trx', 
                'PPP/LPM': 'aprv_flag',
                'LPM No' : 'lpm_id',
                'Order No' : 'order_id',
                'Customer Name' : 'cust_name',
                'Description': 'descr',
                'Weight': 'wgt',
                'Unit Price': 'unit_price',
                'Total Price': 'total_price'
            },
        }
    },
    async mounted(){
      this.$store.dispatch('setOverlay', true)
      this.getCustoms()
      this.$store.dispatch('setOverlay', false)
    },
    computed:{
        formattedData() {
            return this.data.map(item => ({
                ...item,
                wgt: this.formatNumber(item.wgt, 0, 0),
                total_price : this.formatNumber(item.total_price, 2, 2)
            }));
        },
        formattedDetailData() {
            return this.detailData.map(item => ({
                ...item,
                wgt: this.formatNumber(item.wgt, 0, 0),
                unit_price: this.formatNumber(item.unit_price, 2, 2),
                total_price : this.formatNumber(item.total_price, 2, 2)
            }));
        }
    },
    methods:{
    startDownload(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
            }
        },
    finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },   
    closeDialogDetail(event){
            this.dtTrx = ''
            this.customerName = ''
            this.pppLpmNo = ''
            this.orderNo = ''
            this.dialog = false
            this.$store.dispatch('setOverlay', false)
    },
    tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
            
        },
    formatNumber(number, minFrac, maxFrac) {
            var a = parseFloat(number)
            return a.toLocaleString(undefined, {
                minimumFractionDigits: minFrac,
                maximumFractionDigits: maxFrac
            });
        },
    async getCustoms(){
          await axios.get(`${process.env.VUE_APP_URL}/v2/sm/shipment-analysis/Customer`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.customers = res.data.data
          });
      },
    async getDetailData(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period start",
                    visible: true
                };
                return false;
            } else {
            this.$store.dispatch('setOverlay', true)
            var periodStart = '';
            var periodEnd = '';

            periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
            periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/v2/sm/shipment-analysis/Detail?cust_id=${this.customer}&aprv=${this.ppp}&periodStart=${periodStart}&periodEnd=${periodEnd}`,{headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}` }})
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.detailData = res.data.data
          })


         }
              

        },
    async showDialogDetail(event){
            console.log(event)
            await this.getDetailData(event)
            this.dialog = true
            this.dtTrx = event.dt_trx
            this.customerName = event.cust_name.trim()
            this.pppLpmNo = event.lpm_id.trim()
            this.orderNo = event.order_id.trim()
        }, 
    async getPullData(){
         if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period start",
                    visible: true
                };
                return false;
                } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''

                await axios.get(`${process.env.VUE_APP_URL}/v2/sm/shipment-analysis?cust_id=${this.customer}&aprv=${this.ppp}&periodStart=${periodStart}&periodEnd=${periodEnd}`, {headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } })
                .then(res => {
                        this.data = res.data.data
                        this.$store.dispatch('setOverlay', false)
                })

         }
       
      },
    async exportExcel(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                var periodStart = '';
                var periodEnd = '';

                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''

                const response = await axios.get(`${process.env.VUE_APP_URL}/v2/sm/shipment-analysis/ExprtEx??cust_id=${this.customer}&aprv=${this.ppp}&periodStart=${periodStart}&periodEnd=${periodEnd}`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                });
                console.log(response.data);
                return response.data.data
            }
        },   

    }


}
</script>

<style>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>